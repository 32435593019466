import React, { FC, useState } from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"
import Stack from "@components/ui/Stack/Stack"
import Typography from "@components/ui/Typography/Typography"
import { Button } from "@components/ui/Button"
import { useStore } from "@store/index"
import Icon from "@components/ui/Icon/Icon"
import ConfirmModal from "@components/modals/ConfirmModal/ConfirmModal"
import { ProductMetaAdsConnection } from "@framework/types/account"
import accountService from "@services/account.service"

import styles from "./MetaAdsBar.module.scss"

interface MetaAdsBarProps {
  openMetaLinkModal: () => void
}

const MetaAdsBar: FC<MetaAdsBarProps> = observer(({ openMetaLinkModal }) => {
  const {
    productFeedStore: { list },
    accountStore: { account, accountId },
  } = useStore()

  const alert = useAlert()

  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false)

  const handleNotConnectedAccount = () => {
    alert.show("Please connect yout account")
  }

  const handleConnect = () => {
    openMetaLinkModal()
  }

  const handleUnlinkMetaAds = () => {
    const reqBody: ProductMetaAdsConnection = {
      remove: [],
      add: [],
    }
    list.selected.data.forEach((el) => {
      const product = list.productById(el)
      if (product?.connectedAds && product?.connectedAds?.length > 0) {
        const ids = product?.connectedAds.map((el) => el.id)
        reqBody.remove.push({
          productVariantId: Number(el),
          adIds: [...ids],
        })
      }
    })
    if (accountId) {
      accountService.setMetaAdsList(accountId, reqBody).then((res) => {
        if (res.status === 200) {
          alert.success("changes is saved")
          list.reloadProductFeed(true)
        }
      })
    }
  }

  const handleApplyUnlink = () => {
    setIsOpenConfirmModal(false)
    handleUnlinkMetaAds()
    list.selected.selectAll()
  }

  const transform =
    list.selected.selectedCount > 0 ? "translateY(0)" : "translateY(100%)"

  return (
    <>
      <div className={styles.root} style={{ transform }}>
        <Stack direction="row" align="center" justify="space-between">
          <Typography color="light" weight="semibold" type="body1">
            {list.selected.selectedCount} products selected
          </Typography>
          <Stack direction="row" justify="center">
            <Button
              before={<Icon name="link" />}
              color="light"
              onClick={
                account?.metaCredentials?.ID
                  ? handleConnect
                  : handleNotConnectedAccount
              }
            >
              Link to Meta Ads
            </Button>
            <Button
              before={<Icon name="link" />}
              color="light"
              onClick={() => {
                setIsOpenConfirmModal(true)
              }}
            >
              Unlink from Meta Ads
            </Button>
          </Stack>
          <Button
            color="light"
            onClick={() => {
              list.selected.selectAll()
            }}
          >
            Cancel
          </Button>
        </Stack>
      </div>
      <ConfirmModal
        isOpen={isOpenConfirmModal}
        title="Unlink from Meta Ads"
        message="Are you sure you want to unlink connected Meta Ads from selected products?"
        onClose={() => setIsOpenConfirmModal(false)}
        onApply={handleApplyUnlink}
      />
    </>
  )
})

export default MetaAdsBar
