import React, { useState } from "react"
import { useStore } from "@store/index"
import { observer } from "mobx-react-lite"
import clsx from "clsx"
import Stack from "@components/ui/Stack/Stack"
import {
  defaultMetrics,
  defaultSummaryMetrics,
  DefaultSummaryMetricsType,
} from "@framework/types/creativeAnalytics"
import Loader from "@components/ui/Loader/Loader"
import { MetricDescription } from "@framework/types/metrics"
import { metricsDescriptors } from "@framework/constants/metrics"
import SummaryControlPanel from "./SummaryControlPanel"
import KPIOverview from "../components/KPIOverview"
import SummaryChartWithTable from "../components/SummaryChartWithTable/SummaryChartWithTable"

import styles from "./index.module.scss"
import SortingByColor from "../components/ColorSorting/ColorSorting"

type SummaryPageProps = {
  className?: string
}

const extendedMetrics = defaultMetrics.concat([
  "AverageCpc",
  "CPM",
  "Thumbstop",
  "Holdplay",
  "Cpm",
])

const SummaryPage: React.FC<SummaryPageProps> = observer(({ className }) => {
  const {
    creativeAnalyticsStore: { adsCardsData, isADSCardsLoading },
  } = useStore()

  const [metricOptionsValues, setMetricOptionsValues] = useState<
    DefaultSummaryMetricsType[]
  >(defaultSummaryMetrics)

  const [activeMetrics, setActiveMetrics] = useState<MetricDescription[]>(
    metricsDescriptors.filter((md) => extendedMetrics.includes(md.name))
  )
  const metricsComponentMap: Record<DefaultSummaryMetricsType, JSX.Element> = {
    OverallKPIs: <KPIOverview summaryReports={adsCardsData.Summary} />,
    KPIChart: (
      <SummaryChartWithTable
        activeMetrics={activeMetrics}
        setActiveMetrics={setActiveMetrics}
      />
    ),
    TopAds: <></>,
  }
  const renderAnalyticSections = () =>
    metricOptionsValues.map((metricOption) => metricsComponentMap[metricOption])

  return (
    <div className={clsx(styles.root, className)}>
      <Stack direction="column" align="stretch">
        <SummaryControlPanel
          metricOptionsValues={metricOptionsValues}
          setMetricOptionsValues={setMetricOptionsValues}
        />
        <SortingByColor
          activeMetrics={activeMetrics}
          setActiveMetrics={setActiveMetrics}
        />
        {isADSCardsLoading ? <Loader /> : renderAnalyticSections()}
      </Stack>
    </div>
  )
})

export default SummaryPage
