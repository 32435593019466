import React, { useEffect, useState } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import { useHistory } from "react-router-dom"

import { useStore } from "@store/index"
import GMCNotConnectedModal from "@components/modals/GMCNotConnectedModal/GMCNotConnectedModal"
import ServiceDownInterceptor from "@framework/prototypes/ServiceDownInterceptor/ServiceDownInterceptor"
import PublishToGMCModal from "@components/modals/PublishToGMCModal/PublishToGMCModal"
import Table from "../components/Table/Table"
import { MainHeader } from "./MainHeader/MainHeader"
import { ControlHeader } from "./ControlHeader/ControlHeader"
import CompareFeedSidebar from "./CompareFeedSidebar/CompareFeedSidebar"
import { SidebarFilter } from "./SidebarFilter/SidebarFilter"
import SideBarSetting from "./SideBarSettings/SideBarSetting"
import { productFeedListMapper } from "./productFeerListMapper"
import MetaAdsBar from "./MetaAdsBar/MetaAdsBar"
import SideBarMetaLink from "./SideBarMetaLink/SideBarMetaLink"
import { ViewType } from "./types"

import styles from "./ProductFeed.module.scss"

type ProductFeedProps = {
  className?: string
}

const ProductFeed: React.FC<ProductFeedProps> = observer(({ className }) => {
  const history = useHistory()
  const {
    accountStore: { accountId },
    productFeedStore: { list, changes, GMC, isInited, isFeedAvailable },
  } = useStore()

  const [isFilterShown, setFilterShown] = useState(false)
  const [isSettingsShown, setSettingsShown] = useState(false)
  const [isMetaLinkShown, setMetaLinkShown] = useState<boolean>(false)
  const [productId, setProductId] = useState<number | null>(null)
  const [view, setView] = useState<ViewType>("simple")

  useEffect(() => {
    if (accountId && !list.isLoading && !changes.editChangeLoading) {
      list.loadStatus(accountId)
    }
  }, [changes.editChangeLoading, accountId, list.isLoading])

  useEffect(() => {
    if (isInited && !isFeedAvailable) {
      history.replace("./dashboard", { autoImport: true })
    }
  }, [isInited && !isFeedAvailable])

  useEffect(() => {
    if (!accountId) return undefined
    GMC.subscribeOnExportStatus(
      accountId,
      GMC.isExportAvailable ? 45_000 : 10_000
    )
    return GMC.unsubscribeOnExportStatus
  }, [accountId, GMC.isExportAvailable])

  useEffect(() => {
    list.reloadProductFeed(true)

    return () => {
      list.selected.selectAll()
    }
  }, [])

  return (
    <div className={styles.wrappper}>
      <div className={clsx(styles.root, className)}>
        <ServiceDownInterceptor />
        <div className={styles.header}>
          <MainHeader
            view={view}
            onFilter={() => setFilterShown(true)}
            onSetthings={() => setSettingsShown(true)}
            onViewChange={(value) => setView(value)}
          />
          <ControlHeader className={styles.controlHeader} />
        </div>
        {isInited && isFeedAvailable && (
          <div className={styles.body}>
            <Table
              bodyClassName={styles.tableBody}
              viewType={view}
              mapper={productFeedListMapper}
              onRowClick={setProductId}
            />
          </div>
        )}
        <SideBarMetaLink
          show={isMetaLinkShown}
          onClose={() => {
            setMetaLinkShown(false)
          }}
        />

        <SidebarFilter
          show={isFilterShown}
          onClose={() => setFilterShown(false)}
        />

        <SidebarFilter
          show={isFilterShown}
          onClose={() => setFilterShown(false)}
        />

        <SideBarSetting
          show={isSettingsShown}
          onClose={() => setSettingsShown(false)}
        />

        {productId != null && (
          <CompareFeedSidebar
            productId={productId}
            isOpened={productId != null}
            onClose={() => setProductId(null)}
          />
        )}

        <GMCNotConnectedModal
          isOpen={GMC.isPlaceholderModalOpened}
          onClose={() => GMC.showPlaceholderModal()}
        />

        <PublishToGMCModal isOpen={GMC.wasPublishingLunched} />
      </div>
      <MetaAdsBar
        openMetaLinkModal={() => {
          setMetaLinkShown(true)
        }}
      />
    </div>
  )
})

export default ProductFeed
