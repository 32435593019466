import React from "react"
import without from "lodash/without"
import { observer } from "mobx-react-lite"

import { ConnectedAd, ProductFeedInstance } from "@framework/types/account"
import TextDiff from "@components/ui/TextDiff/TextDiff"
import { mbNoData, mbNoDataWithUnit } from "@services/utils"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import { numberWithCommas } from "@components/utils/numberUtils"
import { snakeCaseToTitle } from "@components/utils/stringUtils"
import { isFilterOptionData } from "@store/product-feed/utils"
import { useStore } from "@store/index"
import {
  makeDescriptor,
  Mapper,
  RenderCallbackType,
  ViewCellProps,
} from "./types"
import ImagesViewColumn from "./CompareFeedSidebar/ImageCarusele/ImagesColumn"
import SequenceViewColumn from "./SequenceViewColumn/SequenceViewColumn"
import StatusBadge from "../components/Status/StatusBadge"
import { productStatusMapper } from "./utils"

import styles from "./ProductFeed.module.scss"

interface MoneyCellProps extends ViewCellProps {
  unitName: string
  valueFormatter?: (value: any) => string
}

export const WithUnitCell: React.FC<MoneyCellProps> = ({
  name,
  data,
  changeData,
  unitName,
  valueFormatter = (v) => v.toString(),
}) => {
  const value = (data as any)?.[name] ?? ""
  const change = (changeData?.update as any)?.[name] ?? ""
  const unit = (data as any)?.[unitName] ?? ""

  const valueLabel = value ? valueFormatter?.(value) : ""
  const changeLabel = change || ""

  const node = !changeLabel ? (
    mbNoDataWithUnit(value, unit, valueFormatter)
  ) : (
    <span>
      <TextDiff by="words" oldText={valueLabel} newText={changeLabel} /> {unit}
    </span>
  )
  return (
    <Tooltip lineClamp={2} text={node}>
      {node}
    </Tooltip>
  )
}

export const CompareText: React.FC<ViewCellProps> = ({
  name,
  data,
  changeData,
}) => {
  const value = (data as any)?.[name] ?? ""
  const change = (changeData?.update as any)?.[name] ?? ""

  const node =
    !change || typeof value !== "string" ? (
      mbNoData(value)
    ) : (
      <TextDiff by="words" oldText={value} newText={change} />
    )
  return (
    <Tooltip lineClamp={2} text={node}>
      {node}
    </Tooltip>
  )
}

export const ImageCell: React.FC<ViewCellProps> = ({ data, name }) => {
  const value = (data as any)?.[name]
  const imageSrc =
    !value || typeof value !== "string"
      ? "/images/image-placeholder.svg"
      : value
  return (
    <img className={styles.imageCell} src={imageSrc} alt={name.toString()} />
  )
}

export const LinkCell: React.FC<ViewCellProps> = ({ data, name }) => {
  const value = (data as any)?.[name] ?? ""
  const link = !value || typeof value !== "string" ? "" : value
  const node = <a href={link}>{value}</a>
  if (!link) return <>-</>
  return (
    <Tooltip lineClamp={2} text={node}>
      {node}
    </Tooltip>
  )
}

export const ImagesCell: React.FC<ViewCellProps> = ({ data, name }) => {
  const value = (data as any)?.[name]
  if (!value || !Array.isArray(value)) return null
  return (
    <ImagesViewColumn
      list={value}
    >{`+${value.length} Images`}</ImagesViewColumn>
  )
}

export const StatusCell: React.FC<ViewCellProps> = observer(
  ({ data, name }) => {
    const { productFeedStore } = useStore()

    const changeData = data?.product_id
      ? productFeedStore.changes.changeById(data?.product_id ?? null)?.data
      : null

    const value =
      (changeData?.meta as any)?.[name] ?? (data as any)?.[name] ?? ""
    if (!value || typeof value !== "string") return null
    return <StatusBadge value={value} mapper={productStatusMapper} />
  }
)

export const SelectCell: React.FC<ViewCellProps> = observer(
  ({ name, data, changeData }) => {
    const dataRow = (data as any)?.[name]
    const oldValueLabel = isFilterOptionData(dataRow)
      ? snakeCaseToTitle(dataRow.control ?? "")
      : ""
    const newValueLabel = snakeCaseToTitle(
      (changeData?.update as any)?.[name] ?? ""
    )

    const node = !newValueLabel ? (
      mbNoData(oldValueLabel, snakeCaseToTitle)
    ) : (
      <TextDiff by="words" oldText={oldValueLabel} newText={newValueLabel} />
    )

    return (
      <Tooltip lineClamp={2} text={node}>
        {node}
      </Tooltip>
    )
  }
)

export const ConnectedAdsCell: React.FC<ViewCellProps> = ({ data, name }) => {
  const value = (data as any)?.[name]

  if (!value || !Array.isArray(value) || value.length === 0) {
    return <>-</>
  }
  const adsList = value.map((ad: ConnectedAd) => ad.name).join(" | ")

  const tooltipContent = (
    <div className={styles.connectedAdsTooltip} style={{ width: "400px" }}>
      <div className={styles.tooltipHeader}>Connected Ads:</div>
      {adsList}
    </div>
  )

  return (
    <Tooltip lineClamp={2} text={tooltipContent} overflowWidth={400}>
      <span className={styles.connectedAds}>{adsList}</span>
    </Tooltip>
  )
}

export const renderList: RenderCallbackType = (value) => (
  <SequenceViewColumn list={value} />
)

export const parsePathString = (data: ProductFeedInstance): string[] => {
  if (!data.productType) return [""]
  return data.productType.split(">")
}

const defaultViewTextImageDescriptor = makeDescriptor(CompareText)
const defaultViewImageDescriptor = makeDescriptor(ImageCell)
const defaultViewImagesDescriptor = makeDescriptor(ImagesCell)
const defaultViewLinkDescriptor = makeDescriptor(LinkCell)
const defaultViewSelectDescriptor = makeDescriptor(SelectCell)
const connectedAdsDescriptor = makeDescriptor(ConnectedAdsCell)

export const mapper: Mapper<ProductFeedInstance> = {
  image: {
    name: "image",
    label: "Image",
    view: defaultViewImageDescriptor,
    // renderCallback: (value, _, { name }) => (
    //   <img className={styles.imageCell} src={value} alt={name.toString()} />
    // ),
  },
  title: {
    name: "title",
    label: "Title",
    width: "300px",
    isSortable: true,
    view: defaultViewTextImageDescriptor,
  },
  condition: {
    name: "status",
    label: "Status",
    isSortable: true,
    view: { Component: StatusCell },
  },
  product_id: {
    name: "product_id",
    label: "Id",
    isSortable: true,
    width: "150px",
  },
  availability: {
    name: "availability",
    label: "Availability",
    width: "150px",
    isSortable: true,
    view: defaultViewSelectDescriptor,
  },
  connectedAds: {
    name: "connectedAds",
    label: "Connected Ads",
    width: "150px",
    isSortable: true,
    view: connectedAdsDescriptor,
  },
  priceValue: {
    name: "priceValue",
    label: "Price",
    isSortable: "price_value",
    view: makeDescriptor(WithUnitCell, {
      valueFormatter: (v: any) => numberWithCommas(Number(v), 2),
      unitName: "priceCurrency",
    }),
  },
  salePriceValue: {
    name: "salePriceValue",
    label: "Sale price",
    isSortable: "sale_price_value",
    width: "120px",
    view: makeDescriptor(WithUnitCell, {
      valueFormatter: (v: any) => numberWithCommas(Number(v), 2),
      unitName: "priceCurrency",
    }),
  },
  category: {
    name: "category",
    label: "Category",
    isSortable: true,
    width: "240px",
    view: defaultViewTextImageDescriptor,
  },
  productType: {
    name: "productType",
    label: "Product type",
    isSortable: "product_type",
    width: "240px",
    // getValueCallback: parsePathString,
    // renderCallback: renderList,
  },
  brand: {
    name: "brand",
    label: "Brand",
    width: "150px",
    isSortable: true,
  },
  description: {
    name: "description",
    label: "Product Description",
    isSortable: true,
    width: "240px",
    view: defaultViewTextImageDescriptor,
  },
  itemGroupId: {
    name: "itemGroupId",
    label: "Item group id",
    width: "140px",
    isSortable: "item_group_id",
  },
  link: {
    name: "link",
    label: "Link",
    isSortable: true,
    width: "240px",
    view: defaultViewLinkDescriptor,
    // renderCallback: (value) => <a href={value}>{value}</a>,
  },
  images: {
    name: "images",
    label: "Images",
    isSortable: false,
    view: defaultViewImagesDescriptor,
  },
}

export const productFeedListMapper = without(Object.values(mapper), undefined)

export default mapper
