import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import { useStore } from "@store/index"
import { SortState } from "@pages/MetaAdsChannel/components/Table/Table"
import { ColumnMapper } from "@pages/MetaAdsChannel/components/Table/types"
import HeaderCell from "@pages/MetaAdsChannel/components/Table/TableCell/HeaderCell"
import RowGroup from "@pages/MetaAdsChannel/components/Table/RowGroup"
import CheckBox from "@components/ui/CheckBox/CheckBox/CheckBox"

import styles from "./CompareTableRow.module.scss"

interface HeaderProps<T extends object = any> {
  staticBefore?: number
  staticAfter?: number
  mapper: ColumnMapper<T>[]
  sortState: SortState
  onSort: (column: string) => void
  className?: string
  selectedCheckboxes: string[]
  setSelectedCheckboxes: Dispatch<SetStateAction<string[]>>
}

const Header = observer(
  <T extends object>({
    mapper,
    staticBefore = 0,
    className,
    sortState,
    onSort,
    selectedCheckboxes,
    setSelectedCheckboxes,
  }: HeaderProps<T>) => {
    const {
      creativeAnalyticsStore: { adsCardsData },
    } = useStore()
    if (staticBefore < 0) throw new Error("staticBefore filed should be >= 0 ")

    const [allIds, setAllIds] = useState<string[]>([])
    const [isAllSelected, setIsSelected] = useState<boolean>(false)
    const [isPartialSelected, setIsPartialSelected] = useState<boolean>(false)

    useEffect(() => {
      setAllIds(adsCardsData.AdCreatives.map((item) => item.Id))
    }, [adsCardsData.AdCreatives])

    useEffect(() => {
      if (!allIds.length) return

      const hasSelectedItems = selectedCheckboxes.length > 0
      const hasPartialSelection =
        selectedCheckboxes.length > 0 &&
        selectedCheckboxes.length < allIds.length

      setIsSelected(hasSelectedItems)
      setIsPartialSelected(hasSelectedItems && hasPartialSelection)
    }, [selectedCheckboxes, allIds])

    const handleMainCheckboxChange = (
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      if (e.target.checked) {
        setSelectedCheckboxes(allIds)
      } else {
        setSelectedCheckboxes([])
      }
    }

    const beforeMapper = mapper.slice(0, staticBefore)
    const middleMapper = mapper.slice(staticBefore, mapper.length)

    const renderCell = (mapper: ColumnMapper<T>) => (
      <div className={clsx(styles.cell, styles.headerCell)}>
        <HeaderCell
          label={mapper.label}
          name={mapper.name as string}
          isSortable={mapper.isSortable as boolean}
          sortState={sortState}
          onSort={onSort}
          className={styles.headerCellInner}
        />
      </div>
    )

    return (
      <div className={clsx(styles.header, className)}>
        <CheckBox
          className={styles.tableCheckbox}
          checked={isAllSelected}
          partial={isPartialSelected}
          onChange={handleMainCheckboxChange}
        />
        <RowGroup
          name="static"
          mapper={beforeMapper}
          className={clsx(styles.group, styles.staticGroup)}
          renderCell={renderCell}
        />
        <RowGroup
          name="main"
          mapper={middleMapper}
          className={styles.group}
          renderCell={renderCell}
        />
      </div>
    )
  }
)

export default Header
