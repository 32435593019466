import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import EmptyState from "@components/ui/EmptyState/EmptyState"
import {
  AnalyticsCard,
  AnalyticsFilter,
  filterEqualityMapper,
  filterIncludesTypes,
} from "@framework/types/creativeAnalytics"
import { useStore } from "@store/index"
import {
  MetricDescription,
  MetricsTrendType,
  MetricType,
} from "@framework/types/metrics"
import mapper from "@pages/MetaAdsChannel/Summary/mapper"
import {
  ADSAnalyticsGroupTypeEntity,
  ADSAnalyticsResponse,
} from "@services/creative-analytics"
import { metricsDescriptors } from "@framework/constants/metrics"
import { DateRangeOption } from "@components/ui/DatePicker/types"
import { ViewType } from "@pages/MetaAdsChannel/types"

import styles from "./SummaryChartWithTable.module.scss"
import CompareTable from "./components/Table/CompareTable"
import { calculateMetricsTrend } from "../utils"

interface SummaryChartWithTableProps {
  activeMetrics: MetricDescription[]
  setActiveMetrics: Dispatch<SetStateAction<MetricDescription[]>>
}

const SummaryChartWithTable: FC<SummaryChartWithTableProps> = ({
  activeMetrics,
  setActiveMetrics,
}) => {
  const {
    analyticsFiltersStore: { filtersStatus, allFilters, setAllFilters },
    creativeAnalyticsStore: { adsCardsData, reportData },
  } = useStore()

  const { filters, groupingType, sortByMetric, period, sortColorMetrics } =
    allFilters
  const [filteredCards, setFilteredCards] = useState<AnalyticsCard[]>(
    adsCardsData.AdCreatives
  )

  const [filteredGroups, setFilteredGroups] = useState<
    ADSAnalyticsResponse["data"]["Groupings"]
  >(adsCardsData.Groupings || [])
  const [metricsTrend, setMetricsTrend] = useState<MetricsTrendType>({})
  const filtersRef = React.useRef(filters)

  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([])

  const handleOpenReportDetailsModal = (card: AnalyticsCard) => {
    // TO Do
  }

  useEffect(() => {
    let result: AnalyticsCard[]
    if (reportData) {
      result = adsCardsData.AdCreatives
    } else {
      result =
        filteredCards.length && filters.length
          ? filteredCards
          : adsCardsData.AdCreatives
    }

    let resultGroups =
      groupingType !== "None" && filteredGroups[groupingType] && filteredGroups
        ? filteredGroups[groupingType]
        : []
    if (filtersRef.current.length > filters.length) {
      filtersRef.current = filters
      result = adsCardsData.AdCreatives
      resultGroups =
        groupingType !== "None" && adsCardsData.Groupings[groupingType]
          ? adsCardsData.Groupings[groupingType]
          : []
    }

    if (filters.length) {
      filters.forEach((filter) => {
        const firstFilter = filter[0]
        const secondFilter = filter[1]
        if (
          firstFilter.value === "campaigns" ||
          firstFilter.value === "adSets" ||
          firstFilter.value === "creativeType" ||
          firstFilter.value === "name"
        ) {
          const thirdFilters = filter.filter((f) => f.key === 3)
          if (filterIncludesTypes[secondFilter.value] === "Includes") {
            result = result.filter((card) => {
              if (firstFilter.value === "campaigns")
                return thirdFilters.find((f) => f.value === card.CampaignName)
              if (firstFilter.value === "adSets")
                return thirdFilters.find((f) => f.value === card.AdsetName)
              if (firstFilter.value === "name")
                return thirdFilters.find((f) => f.value === card.Name)
              return thirdFilters.find((f) => f.value === card.Type)
            })
          }
          if (filterIncludesTypes[secondFilter.value] === "Does not include") {
            result = result.filter((card) => {
              if (firstFilter.value === "campaigns")
                return !thirdFilters.find((f) => f.value === card.CampaignName)
              if (firstFilter.value === "adSets")
                return !thirdFilters.find((f) => f.value === card.AdsetName)
              if (firstFilter.value === "name")
                return !thirdFilters.find((f) => f.value === card.Name)
              return !thirdFilters.find((f) => f.value === card.Type)
            })
          }
        } else {
          const firstFilterValue = firstFilter.value
          const secondFilterValue = secondFilter.value
          const thirdFilterValue = Number(filter[2].value)
          const filterEqualityMapperType =
            filterEqualityMapper(secondFilterValue)
          result = result.filter((card) => {
            const metric = card[firstFilterValue]
            if (typeof metric === "object") {
              return filterEqualityMapperType(metric.Value, thirdFilterValue)
            }
            return false
          })
          resultGroups = resultGroups.filter((card) => {
            // @ts-ignore
            const metric = card.Performance[firstFilterValue]
            if (typeof metric === "object") {
              return filterEqualityMapperType(metric.Value, thirdFilterValue)
            }
            return false
          })
        }
      })
    }
    filtersRef.current = filters

    if (sortByMetric?.value && sortByMetric?.order) {
      const cards = [...result]
      const groups: ADSAnalyticsGroupTypeEntity[] =
        groupingType !== "None" && filteredGroups ? [...resultGroups] : []

      cards.sort((a, b) => {
        const aMetric = a[sortByMetric.value] as MetricType
        const bMetric = b[sortByMetric.value] as MetricType
        const order = sortByMetric.order === "asc" ? 1 : -1
        return (aMetric.Value - bMetric.Value) * order
      })

      groups.sort((a, b) => {
        // @ts-ignore
        const aMetric = a.Performance?.[sortByMetric.value] as MetricType
        // @ts-ignore
        const bMetric = b.Performance?.[sortByMetric.value] as MetricType
        const order = sortByMetric.order === "asc" ? 1 : -1
        return (aMetric.Value - bMetric.Value) * order
      })

      setFilteredGroups((prevState) => ({
        ...prevState,
        [groupingType]: groups,
      }))
      setFilteredCards(cards)
    } else {
      setFilteredCards(result)
      if (groupingType === "None") {
        setFilteredGroups(adsCardsData.Groupings)
      } else {
        setFilteredGroups((prevState) => ({
          ...prevState,
          [groupingType]: resultGroups,
        }))
      }
    }
  }, [adsCardsData, reportData, allFilters])

  useEffect(() => {
    const res = calculateMetricsTrend(
      adsCardsData.AdCreatives,
      sortColorMetrics
    )
    setMetricsTrend(res)
  }, [adsCardsData, sortColorMetrics])

  useEffect(() => {
    let newPeriod: DateRangeOption = {
      label: period.label,
      value: period.value,
      range: period.range,
    }
    if (reportData && adsCardsData) {
      if (
        reportData?.settings.time_period.start_date &&
        reportData.settings.time_period.range_type === "static" &&
        filtersStatus !== "reportEditing"
      ) {
        newPeriod = {
          label: reportData?.settings.time_period.range_type,
          value: reportData?.settings.time_period.range_value,
          range: [
            new Date(reportData.settings.time_period.start_date),
            new Date(reportData.settings.time_period.end_date),
          ],
        }
      } else if (
        reportData?.settings.time_period.start_date &&
        reportData.settings.time_period.range_type === "relative" &&
        filtersStatus !== "reportEditing"
      ) {
        const from = new Date()
        const to = new Date()
        switch (reportData.settings.time_period.range_value) {
          case "one_week":
            from.setDate(to.getDate() - 7)
            break
          case "two_weeks":
            from.setDate(to.getDate() - 14)
            break
          case "month":
            from.setDate(to.getDate() - 31)
            break
          default:
        }
        newPeriod = {
          label: reportData?.settings.time_period.range_type,
          value: reportData?.settings.time_period.range_value,
          range: [from, to],
        }
      }

      if (reportData?.settings.metrics.length) {
        const appliedMetrics = metricsDescriptors.filter((md) =>
          reportData?.settings.metrics.includes(md.name)
        )
        setActiveMetrics(appliedMetrics)
      }

      const filtersArray: AnalyticsFilter[][] = []
      const mainFilters = new Map()
      if (reportData?.settings?.filters?.length) {
        let filterNumber = 1
        reportData?.settings.filters.forEach((it, i) => {
          if (i === 0) {
            mainFilters.set(filterNumber, [
              { key: 1, value: it.name },
              { key: 2, value: it.comparator },
              { key: 3, value: it.value },
            ])
          } else if (
            mainFilters.has(filterNumber) &&
            i !== 0 &&
            it.name === mainFilters.get(filterNumber)[0].value &&
            it.comparator === mainFilters.get(filterNumber)[1].value
          ) {
            mainFilters.get(filterNumber).push({ key: 3, value: it.value })
          } else if (
            it.name !== mainFilters.get(filterNumber)[0].value ||
            it.comparator !== mainFilters.get(filterNumber)[1].value
          ) {
            filterNumber += 1
            mainFilters.set(filterNumber, [
              { key: 1, value: it.name },
              { key: 2, value: it.comparator },
              { key: 3, value: it.value },
            ])
          }
        })
      }
      mainFilters.forEach((value, key) => {
        filtersArray.push(value)
      })

      setAllFilters({
        view:
          (reportData?.settings.view_mode.toLowerCase() as ViewType) || "card",
        filters: filtersArray,
        groupingType: reportData?.settings.group
          ? reportData.settings.group
          : "None",
        sortByMetric: reportData?.settings?.sort_option?.type
          ? {
              value: reportData.settings.sort_option.type,
              order: reportData.settings.sort_option.order,
            }
          : null,
        period: newPeriod,
        sortColorMetrics: reportData?.settings?.color_sort_options || [],
      })
    } else {
      setAllFilters({
        view: "card",
        filters: [],
        groupingType: "None",
        sortByMetric: null,
        period: newPeriod,
        sortColorMetrics: [],
      })
    }
  }, [reportData, adsCardsData])

  return (
    <div className={styles.root}>
      <EmptyState />
      <CompareTable
        className={styles.table}
        mapper={Object.values(mapper(activeMetrics, metricsTrend))}
        data={filteredCards}
        summary={adsCardsData.Summary}
        metricsTrend={metricsTrend}
        onRowClick={handleOpenReportDetailsModal}
        selectedCheckboxes={selectedCheckboxes}
        setSelectedCheckboxes={setSelectedCheckboxes}
      />
    </div>
  )
}

export default SummaryChartWithTable
